import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import WreathImg from "../images/wreath-names.png"

const HeaderBox = styled.div`
  text-align: center;
  color: black;
  margin-top: 20px;
  margin-bottom: 20px;
`

const HeaderContainer = styled.h1`
  margin: 0;
`

const LinkContainer = styled.div`
  display: inline-block;
  margin: 0 30px;
  vertical-align: top;
`

const Logo = styled.img`
  max-width: 330px;
  width: 100%;
`

const Header = ({ siteTitle }) => (
  <HeaderBox>
    <div>
      <HeaderContainer>
        <LinkContainer>
          {/* <StyledLink to="/">
            <div>Sara Croxford</div>
            <div>&</div>
            <div>Tim Lee</div>
          </StyledLink> */}
          <a href="/">
            <Logo src={WreathImg} />
          </a>
        </LinkContainer>
      </HeaderContainer>
    </div>
  </HeaderBox>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
