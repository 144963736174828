import React from "react"
import Helmet from "react-helmet"

function SEO({ description, lang, meta, title }) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
    >
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      {/* <link
        href="https://fonts.googleapis.com/css?family=Libre+Caslon+Text&display=swap"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      /> */}
      <link
        href="https://fonts.googleapis.com/css?family=Amatic+SC&display=swap"
        rel="stylesheet"
      ></link>
      <link
        href="https://fonts.googleapis.com/css?family=Kreon&display=swap"
        rel="stylesheet"
      ></link>
      <link
        href="https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap"
        rel="stylesheet"
      ></link>
      <meta name="robots" content="noindex"></meta>
      {/* <link
        href="https://fonts.googleapis.com/css?family=Sacramento&display=swap"
        rel="stylesheet"
      ></link>
      <link href="https://fonts.googleapis.com/css?family=Literata&display=swap" rel="stylesheet"></linl> */}
    </Helmet>
  )
}

export default SEO
